import Head from 'next/head';
import InfiniteScroll from 'common/InfiniteScroll';
import {
  Meta,
  fetchHomePageWidgets,
  Widget,
  publicSession, WidgetBase, WidgetContinueCarousel, FetchPersonalWidgets,
} from 'common/apiService';
import Layout from 'common/Layout/Layout';
import WidgetManager from 'common/WidgetManager';
import usePagination from 'common/usePagination';
import { __t } from 'common/i18n';
import WidgetLoading from 'common/WidgetsLoading';
import Slider from 'common/Slider';
import styled from '@emotion/styled';
import { useEffect, useState } from 'react';
import { useUser } from '../common/CacheProvider';
// import { setUserCookieToSEntry } from 'common/utils/cookieManage';

type HomePageProps = {
  initialData: { data: Widget[], meta: Meta, },
  initialUrl: string,
};

const sliderRatio = 9 / 16;
const bottomOffsetPercentage = {
  sm: 16,
  md: 26,
  xl: 30,
};

const SliderWrapper = styled.div(({ theme }) => ({
  marginBottom: theme.spacing(8),
  ...theme.mixins.create(bottomOffsetPercentage, (b) => ({ marginBottom: `-${b * sliderRatio}%` })),
}));

export default function HomePage({ initialData, initialUrl }: HomePageProps) {
  const [newData, setNewData] = useState<WidgetBase<'continue_watching', WidgetContinueCarousel> | any>();
  const { authorized, loaded } = useUser();
  useEffect(() => {
    if (authorized && loaded) {
      handlePersonalHomePageWidgets();
    }
  }, [authorized, loaded]);
  const handlePersonalHomePageWidgets = async () => {
    const data = await FetchPersonalWidgets();
    data.data && setNewData(data.data[0]);
  };
  const { data: widgets, getInfiniteScrollProps, hasMore } = usePagination({ initialData, initialUrl });
  const slider = widgets.find(({ type }) => type === 'main_slider') as Widget<'main_slider'>;
  const newWidgets:Widget[] = newData ? [
    ...widgets.slice(0, 2),
    newData,
    ...widgets.slice(2),
  ] : [...widgets];
  return (
    <>
      <Head>
        <title>{__t('siteName')} | {__t('watch.movies.series')}</title>
        <link rel="canonical" href='https://filmnet.ir' />
      </Head>

      <SliderWrapper>
        {slider && <Slider items={slider.configuration.items} serverTime={initialData.meta.server_date_time} />}
      </SliderWrapper>

      <Layout disableTopGutter disableFooter={hasMore}>
        {newWidgets.length > 0 && (
          <InfiniteScroll {...getInfiniteScrollProps()} loader={<WidgetLoading />}>
            <WidgetManager widgets={newWidgets}/>
          </InfiniteScroll>
        )}
      </Layout>
    </>
  );
}

const filterWidgetItems = (widget: Widget) => {
  widget?.configuration?.items?.splice(15);
  return widget;
};

export async function getServerSideProps({ req, res }) {
  const country = req.headers['x-ip-country'] || '';
  const clientIP = req.headers['x-real-ip'] || req.headers['x-forwarded-for'] || req.connection.remoteAddress;
  // setUserCookieToSEntry(req);
  res.setHeader('x-ip-country', country);
  if (country) publicSession.defaults.headers.get['x-ip-country'] = country;
  if (clientIP) publicSession.defaults.headers.common['x-scrat-forwarded-for'] = clientIP;

  const count = 5;
  const offset = 0;
  return await fetchHomePageWidgets({ count, offset })
    .then(({ data, meta }) => {
      return {
        props: {
          initialData: { data: data.map(filterWidgetItems), meta },
          initialUrl: `/widgets/home-page?count=${count}`,
        },
      };
    }).catch((err) => {
      throw new Error(err);
    });
}
